import React from 'react'
import styled from 'styled-components'

import ContentDefault from '../components/container'
import Layout from '../components/layout'
import banner from '../images/banner.png'

const Banner = styled.div`
  width: 100%;
  height: 160px;
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`

const Content = styled(ContentDefault)`
  padding: 50px 0;
`

const Title = styled.h1`
  color: #102549;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 32px;
`

const Subtitle = styled.h2`
  font-size: 26px;
  font-weight: 600;
`

const Esportes = () => (
  <Layout title='Esportes' page='esportes'>
    <Banner />
    <Content>
      <Title>Esportes</Title>

      <Subtitle>Alto Rendimento</Subtitle>
      <ul>
        <li>
          Modalidades Aquáticas
          <ul>
            <li>Natação</li>
            <li>Polo Aquático</li>
            <li>Maratona Aquática</li>
          </ul>
        </li>
        <li>
          Modalidades de Quadra
          <ul>
            <li>Vôlei Feminino</li>
            <li>Vôlei Masculino</li>
            <li>Basquete Feminino</li>
            <li>Basquete Masculino</li>
          </ul>
        </li>
        <li>
          Modalidades de Raquetes
          <ul>
            <li>Tênis</li>
            <li>Tênis de Mesa</li>
            <li>Badminton</li>
          </ul>
        </li>
        <li>
          Modalidades de Lutas
          <ul>
            <li>Judô</li>
            <li>Karatê</li>
          </ul>
        </li>
        <li>
          Modalidades de Areia
          <ul>
            <li>Vôlei de Areia</li>
            <li>Beach Tênis</li>
          </ul>
        </li>
        <li>
          Modalidades Terrestres
          <ul>
            <li>Atletismo</li>
          </ul>
        </li>
        <li>
          Modalidades Náutica
          <ul>
            <li>Vela</li>
            <li>Remo</li>
          </ul>
        </li>
      </ul>

      <Subtitle>Escola de Esportes</Subtitle>
      <ul>
        <li>Tênis</li>
        <li>Judô</li>
        <li>Karatê</li>
        <li>Vôlei</li>
        <li>Natação</li>
      </ul>

      <Subtitle>Esporte Social</Subtitle>
      <ul>
        <li>Natação para Portadores de Deficiência</li>
        <li>Futebol para Surdos</li>
      </ul>
    </Content>
  </Layout>
)

export default Esportes
